import { rem } from 'polished';
import styled from 'styled-components';

const PreferenceHeader = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLight};
    margin-bottom: ${rem(16)};
    padding: ${rem(8)} 0;
`;

export default PreferenceHeader;
