import { rem } from 'polished';
import styled from 'styled-components';
import { cssHelpers } from '@luigiclaudio/ga-baseline-ui/helpers';

const PreferencesDivider = styled.div`
    ${cssHelpers.clearfix}
    margin: ${rem(40)} 0;
`;

export default PreferencesDivider;
