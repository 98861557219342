const handleCustomerPortal = async ({ token, returnUrl }) => {
    const response = await fetch('/.netlify/functions/customer-portal', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
        body: JSON.stringify({ returnUrl }),
    });

    // eslint-disable-next-line no-console
    const results = await response.json();

    // TODO Handle redirect
    window.location.href = await results.url;
};

export default handleCustomerPortal;
