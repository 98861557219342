const accountUtils = {
    subscriptionLabel: (subscriptionType) => {
        const subscriptionTypeLabel = [
            {
                type: 'free',
                message: 'account.subscription.type.free.label',
            },
            {
                type: 'premium',
                message: 'account.subscription.type.premium.label',
            },
        ];

        const label = subscriptionTypeLabel.find(
            (subscription) => subscription.type === subscriptionType,
        );

        return label.message;
    },
    greetingTime: (intl, callback = () => {}) => {
        let now;
        let timeOfDay;

        setInterval(() => {
            now = new Date();
            callback(intl.formatTime(now));
        }, 1000);

        const nowHours = new Date().getHours();

        if (nowHours >= 5) {
            timeOfDay = 'morning';
        }
        if (nowHours >= 12) {
            timeOfDay = 'afternoon';
        }
        if (nowHours < 5 || nowHours >= 17) {
            timeOfDay = 'evening';
        }

        const greetingLabel = () => {
            const timeOfDayLabel = [
                {
                    type: 'morning',
                    message: 'account.greeting.timeOfDay.morning',
                },
                {
                    type: 'afternoon',
                    message: 'account.greeting.timeOfDay.afternoon',
                },
                {
                    type: 'evening',
                    message: 'account.greeting.timeOfDay.evening',
                },
            ];

            const label = timeOfDayLabel.find((time) => time.type === timeOfDay);

            return label.message;
        };

        return {
            timeOfDay,
            greetingLabel,
        };
    },
};

export default accountUtils;
