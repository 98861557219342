import React, { useEffect, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import {
    TransitionItem,
    useHasMounted,
    Loader,
    HelmetDetails,
} from '@luigiclaudio/ga-baseline-ui/helpers';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { Col, Container, Row } from '@luigiclaudio/ga-baseline-ui/layout';
import { AlertFeedback } from '@luigiclaudio/ga-baseline-ui/alert';
import productTypeList from '../../../data/productTypeList.json';
import ProductCatalogAsideFilters from '../ProductCatalogAsideFilters';
import BrandDetails from '../BrandDetails';
import BrandContext from '../../context/BrandContext';
import brandDetailsOnStageInitialState from '../../context/brandDetailsOnStageInitialState';
import useAppUser from '../../../services/useAppUser';
import brandUtils from '../../../utils/brandUtils';
import countryUtils from '../../../utils/countryUtils';
import appConfig from '../../../data/appConfig.json';
import useAllProductUserCache from '../../../services/useAllProductUserCache';

const Wishlist = () => {
    const { countries } = appConfig;
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const { username, isErrorSubscription, isLoadingSubscription } = useAppUser();
    const { allProductsUserFromClient } = useAllProductUserCache();

    const intl = useIntl();
    const {
        brandProductsOnStage,
        brandDetailsOnStage,
        setBrandOnStage,
        productTypeOnStage,
        searchFilterTypes,
        setBrandDetailsOnStage,
        setBrandProductsOnStage,
        setProductTypeListOnSelection,
        setProductFilterList,
        setProductTypeOnStage,
        catalogFiltersOnStage,
    } = useContext(BrandContext);

    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    const [productsByRetailerByUser, setProductByRetailerByUser] = useState([]);

    useEffect(() => {
        setBrandDetailsOnStage({
            ...brandDetailsOnStageInitialState,
        });
        setBrandProductsOnStage(undefined);
        setProductFilterList(undefined);
        setProductTypeOnStage(undefined);
        return () => {
            setProductFilterList(undefined);
            setProductTypeOnStage(undefined);
        };
    }, []);

    useEffect(() => {
        setBrandOnStage(
            intl.formatMessage(
                { id: 'wishlist.title' },
                {
                    username,
                },
            ),
        );
    }, [username]);

    useEffect(() => {
        if (allProductsUserFromClient) {
            setProductByRetailerByUser(allProductsUserFromClient?.allProducts?.data);
        }
    }, [allProductsUserFromClient]);

    useEffect(() => {
        const availableProducts = [];
        const availabelProductTypes = [];

        availableProducts.push(productsByRetailerByUser);
        availableProducts.forEach((products) => {
            const filteredAvailableProducts = brandUtils.productByProductTypeOnStage(
                products,
                productTypeOnStage,
            );

            filteredAvailableProducts?.forEach((product) =>
                availabelProductTypes.push(...product.intlKeywords),
            );
        });

        const count = {};
        const countArray = [];

        availabelProductTypes.forEach((item) => {
            count[item] = (count[item] || 0) + 1;
        });

        Object.entries(count).forEach(([countKey, countValue]) => {
            countArray.push({ keyword: countKey, amount: countValue });
        });

        const setPriorityByAZ = countArray.sort((a, b) => a.keyword.localeCompare(b.keyword, 'en'));
        const priorityByAZKeywords = setPriorityByAZ.map((item) => item.keyword);
        const narrowProductTypesAZ = priorityByAZKeywords.filter((item) =>
            productTypeList.includes(item.toLowerCase()),
        );
        setProductTypeListOnSelection(
            searchFilterTypes
                ? narrowProductTypesAZ.filter((item) => item.includes(searchFilterTypes))
                : narrowProductTypesAZ,
        );
    }, [searchFilterTypes, productTypeOnStage, productsByRetailerByUser, brandDetailsOnStage]);

    useEffect(() => {
        setBrandDetailsOnStage({
            brand: username,
            country: countryUtils.displayCountryName(intl, countries.join()),
            products: brandUtils.productByProductTypeOnStage(
                productsByRetailerByUser,
                productTypeOnStage,
            ),
        });
        setBrandProductsOnStage(productsByRetailerByUser);
    }, [productsByRetailerByUser, productTypeOnStage]);

    useEffect(() => {
        const { products } = brandDetailsOnStage;

        /** Reset list when the last filtered item is removed from list */
        if (isEmpty(products) && !isEmpty(brandProductsOnStage)) {
            setProductTypeOnStage(undefined);
            setProductTypeListOnSelection([]);
        }
    }, [productsByRetailerByUser, brandProductsOnStage]);

    if (username && isErrorSubscription) {
        return (
            <AlertFeedback
                inProp
                alertType="danger"
                alertMessage={intl.formatMessage({ id: 'user.isErrorSubscription' })}
            />
        );
    }

    if (
        (!allProductsUserFromClient || isLoadingSubscription) &&
        hasMounted &&
        !productTypeOnStage &&
        !catalogFiltersOnStage
    ) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    if (hasMounted && isEmpty(brandProductsOnStage)) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    return (
        <>
            <HelmetDetails
                title={intl.formatMessage(
                    { id: 'helmet.wishlist.title' },
                    {
                        username,
                    },
                )}
            />
            <DefaultTemplate
                bgStart={renderBgColor}
                bgEnd={renderBgColor}
                isFullWidth
                content={
                    <Container isFullHeight>
                        <Row isFullHeight>
                            <Col xxs={30}>
                                {hasMounted && (
                                    <>
                                        <TransitionItem inProp appear mountOnEnter>
                                            <ProductCatalogAsideFilters />
                                        </TransitionItem>
                                        <BrandDetails />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                }
            />
        </>
    );
};

export default Wishlist;
