import styled from 'styled-components';
import { rem } from 'polished';
import { h2Styles } from '@luigiclaudio/ga-baseline-ui/typography';

const DashboardTime = styled.span.attrs(() => ({
    colorValue: 'muted',
}))`
    ${h2Styles}

    align-items: flex-end;
    display: flex;
    min-height: ${rem(48)};
`;

export default DashboardTime;
