import { useMutation } from '@apollo/client';
import { UPDATE_USER } from './actionTypes';

const useUpdateAppUser = (onCompletedMutation = null) => {
    const [
        updateUser,
        { loading: isLoadingUserPreferences, error: isErrorUserPreferences },
    ] = useMutation(UPDATE_USER, { onCompleted: onCompletedMutation });

    return { updateUser, isLoadingUserPreferences, isErrorUserPreferences };
};

export default useUpdateAppUser;
