import React from 'react';
import { useIntl } from 'react-intl';
import { Router } from '@reach/router';
import { PrivateRoute } from '@luigiclaudio/ga-auth-theme';
import Dashboard from '../components/app/Dashboard';
import Account from '../components/app/Account';
import Wishlist from '../components/app/Wishlist';

const App = () => {
    const intl = useIntl();
    const siteName = intl.formatMessage({ id: 'siteName' });
    return (
        <Router basepath="/app">
            <PrivateRoute siteName={siteName} component={Dashboard} path="/" />
            <PrivateRoute siteName={siteName} component={Account} path="/account" />
            <PrivateRoute siteName={siteName} component={Wishlist} path="/wishlist" />
        </Router>
    );
};

export default App;
