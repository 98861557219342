import styled from 'styled-components';
import { rem } from 'polished';

const PreferenceList = styled.ul`
    list-style: none;
    margin: 0 0 ${rem(16)};
    padding: 0;
`;

export default PreferenceList;
