import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import { AuthAccount, useUser, AuthLogoutButton } from '@luigiclaudio/ga-auth-theme';
import { H1, H2, H3, LargeH1, P } from '@luigiclaudio/ga-baseline-ui/typography';
import { Loader, useHasMounted, TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { Button } from '@luigiclaudio/ga-baseline-ui/button';
import { AlertFeedback } from '@luigiclaudio/ga-baseline-ui/alert';
import handleCustomerPortal from '../../../utils/handleCustomerPortal';
import useAppUser from '../../../services/useAppUser';
import accountUtils from '../../../utils/accountUtils';
import PreferenceContainer from './PreferenceContainer';
import useUpdateAppUser from '../../../services/useUpdateAppUser';
import PreferenceGroups from './PreferenceGroups';
import PreferenceCountries from './PreferenceCountries';
import AccountDetailsCol from './AccountDetailsCol';
import AccountDetailsColDivider from './AccountDetailsColDivider';
import PreferenceHeader from './PreferenceHeader';
import MainContainer from '../../pageLayout/MainContainer';
import PreferencesDivider from './PreferencesDivider';
import appConfig from '../../../data/appConfig.json';
import SiteFooter from '../../pageLayout/SiteFooter';
import DashboardWelcome from './Dashboard/DashboardWelcome';
import DashboardTime from './Dashboard/DashboardTime';
import BrandContext from '../../context/BrandContext';
import handleOnUserUpdateUtils from '../../../utils/handleOnUserUpdateUtils';

const Account = ({ location }) => {
    const { multiCountry } = appConfig;
    const intl = useIntl();
    const client = useApolloClient();
    const theme = useContext(ThemeContext);
    const { userFromClient, setUserFromClient } = useContext(BrandContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;
    const [user] = useUser();
    const { href: currentLocation } = location;
    const {
        isLoadingSubscription,
        isErrorSubscription,
        subscriptionType,
        userActiveGroups,
        userActiveCountries,
        username,
    } = useAppUser();

    const [preferenceHasCompleted, setPreferenceHasCompleted] = useState(false);
    const [isLoadingCustomerPortal, setIsLoadingCustomerPortal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [now, setNow] = useState();

    const { updateUser, isLoadingUserPreferences } = useUpdateAppUser(() =>
        setPreferenceHasCompleted(true),
    );

    useEffect(() => {
        if (preferenceHasCompleted) {
            setTimeout(() => {
                setIsLoading(false);
            }, 350);
        }
    }, [preferenceHasCompleted]);

    useEffect(() => {
        accountUtils.greetingTime(intl, (v) => setNow(v));
    }, []);

    if (isLoadingSubscription && hasMounted) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    if (username && isErrorSubscription) {
        return (
            <AlertFeedback
                inProp
                alertType="danger"
                alertMessage={intl.formatMessage({ id: 'user.isErrorSubscription' })}
            />
        );
    }

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <TransitionItem appear inProp>
                    <MainContainer>
                        <SiteContentWrapper>
                            <Container>
                                <Row justifyContent="center">
                                    <Col xxs={30} lg={26}>
                                        <DashboardWelcome>
                                            <TransitionItem inProp={!!now} appear>
                                                <DashboardTime>{now}</DashboardTime>
                                            </TransitionItem>
                                            <TransitionItem inProp={!!username} appear>
                                                <LargeH1>
                                                    <FormattedMessage
                                                        id="account.greeting"
                                                        values={{
                                                            username,
                                                            greeting: intl.formatMessage({
                                                                id: accountUtils
                                                                    .greetingTime(intl)
                                                                    .greetingLabel(),
                                                            }),
                                                        }}
                                                    />
                                                </LargeH1>
                                            </TransitionItem>
                                        </DashboardWelcome>
                                    </Col>

                                    <Col xxs={30} lg={26}>
                                        <Row justifyContent="center">
                                            <Col xxs={30} sm={12} lg={8}>
                                                <H1>
                                                    <FormattedMessage id="account.preferences.title" />
                                                </H1>
                                            </Col>
                                            <Col xxs={30} sm={18} lg={22}>
                                                <Row>
                                                    <Col xxs={30}>
                                                        <PreferenceHeader>
                                                            <H2>
                                                                <FormattedMessage id="account.preferences.brands.title" />
                                                            </H2>
                                                        </PreferenceHeader>
                                                    </Col>
                                                    <AccountDetailsCol>
                                                        <PreferenceGroups
                                                            userActiveGroups={userActiveGroups}
                                                            onClickGroup={(group) => {
                                                                handleOnUserUpdateUtils.propertyArray(
                                                                    {
                                                                        userFromClient,
                                                                        client,
                                                                        setUserFromClient,
                                                                        updateUser,
                                                                        property: { groups: group },
                                                                    },
                                                                );
                                                                setPreferenceHasCompleted(false);
                                                                setIsLoading(true);
                                                            }}
                                                            hasCompleted={
                                                                !isLoadingUserPreferences &&
                                                                preferenceHasCompleted
                                                            }
                                                            isLoadingUserPreferences={isLoading}
                                                        />
                                                    </AccountDetailsCol>
                                                    <AccountDetailsColDivider />
                                                    {multiCountry && (
                                                        <AccountDetailsCol>
                                                            <PreferenceCountries
                                                                userActiveCountries={
                                                                    userActiveCountries
                                                                }
                                                                onClickCountry={(country) => {
                                                                    handleOnUserUpdateUtils.propertyArray(
                                                                        {
                                                                            userFromClient,
                                                                            client,
                                                                            setUserFromClient,
                                                                            updateUser,
                                                                            property: {
                                                                                countries: country,
                                                                            },
                                                                        },
                                                                    );
                                                                    setPreferenceHasCompleted(
                                                                        false,
                                                                    );
                                                                    setIsLoading(true);
                                                                }}
                                                                hasCompleted={
                                                                    !isLoadingUserPreferences &&
                                                                    preferenceHasCompleted
                                                                }
                                                                isLoadingUserPreferences={isLoading}
                                                            />
                                                        </AccountDetailsCol>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xxs={30}>
                                        <PreferencesDivider />
                                    </Col>
                                    <Col xxs={30} lg={26}>
                                        <Row justifyContent="center">
                                            <Col xxs={30} sm={12} lg={8}>
                                                <H1>
                                                    <FormattedMessage id="account.account.title" />
                                                </H1>
                                            </Col>
                                            <Col xxs={30} sm={18} lg={22}>
                                                <Row>
                                                    <Col xxs={30}>
                                                        <PreferenceHeader>
                                                            <H2>
                                                                <FormattedMessage id="account.account.subscription.title" />
                                                            </H2>
                                                        </PreferenceHeader>
                                                    </Col>
                                                    <AccountDetailsCol>
                                                        <PreferenceContainer>
                                                            {subscriptionType && (
                                                                <>
                                                                    <H3>
                                                                        <FormattedMessage
                                                                            id="account.account.subscription.manageSubscription.title"
                                                                            values={{
                                                                                subscription: (
                                                                                    <FormattedMessage
                                                                                        id={accountUtils.subscriptionLabel(
                                                                                            subscriptionType,
                                                                                        )}
                                                                                    />
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </H3>
                                                                    <P>
                                                                        <FormattedMessage
                                                                            id="account.account.subscription.manageSubscription.description"
                                                                            values={{
                                                                                subscription: (
                                                                                    <FormattedMessage
                                                                                        id={accountUtils.subscriptionLabel(
                                                                                            subscriptionType,
                                                                                        )}
                                                                                    />
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </P>
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleCustomerPortal({
                                                                                token: user.token,
                                                                                returnUrl: currentLocation,
                                                                            });
                                                                            setIsLoadingCustomerPortal(
                                                                                true,
                                                                            );
                                                                        }}
                                                                        skin="muted"
                                                                        loading={
                                                                            isLoadingCustomerPortal
                                                                        }
                                                                        disabled={isLoading}
                                                                    >
                                                                        <FormattedMessage id="account.account.subscription.manage.button.label" />
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </PreferenceContainer>
                                                    </AccountDetailsCol>
                                                    <AccountDetailsColDivider />
                                                </Row>
                                                <Row>
                                                    <Col xxs={30}>
                                                        <PreferenceHeader>
                                                            <H2>
                                                                <FormattedMessage id="account.account.account.title" />
                                                            </H2>
                                                        </PreferenceHeader>
                                                    </Col>
                                                    <AccountDetailsCol>
                                                        <PreferenceContainer>
                                                            <AuthAccount
                                                                disabled={isLoading}
                                                                buttonSkin="muted"
                                                                updatedUserCallback={({
                                                                    currentUsername,
                                                                }) => {
                                                                    handleOnUserUpdateUtils.username(
                                                                        {
                                                                            userFromClient,
                                                                            client,
                                                                            setUserFromClient,
                                                                            updateUser,
                                                                            username: currentUsername,
                                                                        },
                                                                    );
                                                                }}
                                                            />
                                                        </PreferenceContainer>
                                                    </AccountDetailsCol>
                                                    <AccountDetailsColDivider />
                                                    <AccountDetailsCol>
                                                        <PreferenceContainer>
                                                            <H3>
                                                                <FormattedMessage id="account.account.signout.title" />
                                                            </H3>
                                                            <P>
                                                                <FormattedMessage
                                                                    id="account.account.signout.subtitle"
                                                                    values={{
                                                                        username,
                                                                    }}
                                                                />
                                                            </P>
                                                            <AuthLogoutButton
                                                                btnType="default"
                                                                skin="muted"
                                                                disabled={isLoading}
                                                                logoutCallback={() =>
                                                                    setUserFromClient(undefined)
                                                                }
                                                            />
                                                        </PreferenceContainer>
                                                    </AccountDetailsCol>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </SiteContentWrapper>
                    </MainContainer>
                </TransitionItem>
            }
            footer={<SiteFooter />}
        />
    );
};

Account.propTypes = {
    location: PropTypes.shape({
        href: PropTypes.string,
    }).isRequired,
};

export default Account;
