import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { H3, P } from '@luigiclaudio/ga-baseline-ui/typography';
import PreferenceCountriesFeedbackContainer from './PreferenceCountriesFeedbackContainer';
import PreferenceContainer from '../PreferenceContainer';
import PreferenceLabelHightlight from '../PreferenceLabelHightlight';
import PreferenceList from '../PreferenceList';
import PreferenceListitemAction from '../PreferenceListitemAction';
import appConfig from '../../../../data/appConfig.json';
import countryUtils from '../../../../utils/countryUtils';

const PreferenceCountries = ({
    intl,
    userActiveCountries,
    hasCompleted,
    isLoadingUserPreferences,
    onClickCountry,
}) => {
    const { countries } = appConfig;

    const handleOnClickCountry = (country) => {
        onClickCountry(country);
    };

    if (!userActiveCountries) {
        return null;
    }

    return (
        <PreferenceContainer>
            <H3>
                <FormattedMessage id="account.preferences.brands.countries.title" />
            </H3>
            <P>
                <FormattedMessage id="account.preferences.brands.countries.subtitle" />
            </P>
            <PreferenceCountriesFeedbackContainer>
                <P>
                    <FormattedMessage
                        id="account.preferences.brands.countries.overview"
                        values={{
                            activeCountries: !isEmpty(userActiveCountries) ? (
                                <PreferenceLabelHightlight>
                                    {userActiveCountries
                                        .map((item) => countryUtils.displayCountryName(intl, item))
                                        .join(', ')}
                                </PreferenceLabelHightlight>
                            ) : null,
                            activeCountriesAmount: userActiveCountries.length,
                        }}
                    />
                </P>
            </PreferenceCountriesFeedbackContainer>
            <PreferenceList>
                {countries.map((country, countryIndex) => {
                    return (
                        <PreferenceListitemAction
                            isActiveItem={userActiveCountries.includes(country)}
                            activeItems={userActiveCountries}
                            key={countryIndex}
                            itemLabel={countryUtils.displayCountryName(intl, country)}
                            onClick={() => handleOnClickCountry(country)}
                            hasCompleted={hasCompleted}
                            isLoadingUserPreferences={isLoadingUserPreferences}
                        />
                    );
                })}
            </PreferenceList>
        </PreferenceContainer>
    );
};

PreferenceCountries.propTypes = {
    intl: PropTypes.shape().isRequired,
    userActiveCountries: PropTypes.arrayOf(PropTypes.string),
    hasCompleted: PropTypes.bool,
    isLoadingUserPreferences: PropTypes.bool,
    onClickCountry: PropTypes.func.isRequired,
};

PreferenceCountries.defaultProps = {
    hasCompleted: false,
    isLoadingUserPreferences: false,
    userActiveCountries: undefined,
};

export default injectIntl(PreferenceCountries);
