import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { H3, P } from '@luigiclaudio/ga-baseline-ui/typography';
import PreferenceGroupsFeedbackContainer from './PreferenceGroupsFeedbackContainer';
import PreferenceContainer from '../PreferenceContainer';
import PreferenceLabelHightlight from '../PreferenceLabelHightlight';
import PreferenceList from '../PreferenceList';
import brandUtils from '../../../../utils/brandUtils';
import PreferenceListitemAction from '../PreferenceListitemAction';
import appConfig from '../../../../data/appConfig.json';

const PreferenceGroups = ({
    intl,
    userActiveGroups,
    hasCompleted,
    isLoadingUserPreferences,
    onClickGroup,
}) => {
    const { groups } = appConfig;

    const handleOnClickGroup = (group) => {
        onClickGroup(group);
    };

    if (!userActiveGroups) {
        return null;
    }

    return (
        <PreferenceContainer>
            <H3>
                <FormattedMessage id="account.preferences.brands.categories.title" />
            </H3>
            <P>
                <FormattedMessage id="account.preferences.brands.categories.subtitle" />
            </P>
            <PreferenceGroupsFeedbackContainer>
                <P>
                    <FormattedMessage
                        id="account.preferences.brands.categories.overview"
                        values={{
                            activeGroup: (
                                <PreferenceLabelHightlight>
                                    {userActiveGroups
                                        .map((item) =>
                                            intl.formatMessage({
                                                id: brandUtils.groupLabel(item),
                                            }),
                                        )
                                        .join(', ')}
                                </PreferenceLabelHightlight>
                            ),
                            activeGroupAmount: userActiveGroups.length,
                        }}
                    />
                </P>
            </PreferenceGroupsFeedbackContainer>
            <PreferenceList>
                {groups.map((group, groupIndex) => {
                    return (
                        <PreferenceListitemAction
                            isActiveItem={userActiveGroups.includes(group)}
                            activeItems={userActiveGroups}
                            key={groupIndex}
                            itemLabel={intl.formatMessage({ id: brandUtils.groupLabel(group) })}
                            onClick={() => handleOnClickGroup(group)}
                            hasCompleted={hasCompleted}
                            isLoadingUserPreferences={isLoadingUserPreferences}
                        />
                    );
                })}
            </PreferenceList>
        </PreferenceContainer>
    );
};

PreferenceGroups.propTypes = {
    intl: PropTypes.shape().isRequired,
    userActiveGroups: PropTypes.arrayOf(PropTypes.string),
    hasCompleted: PropTypes.bool,
    isLoadingUserPreferences: PropTypes.bool,
    onClickGroup: PropTypes.func.isRequired,
};

PreferenceGroups.defaultProps = {
    hasCompleted: false,
    isLoadingUserPreferences: false,
    userActiveGroups: undefined,
};

export default injectIntl(PreferenceGroups);
