import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { H4 } from '@luigiclaudio/ga-baseline-ui/typography';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { Button } from '@luigiclaudio/ga-baseline-ui/button';
import PreferenceListitemActionContainer from './PreferenceListitemActionContainer';
import PreferenceListItem from './PreferenceListItem';

const PreferenceListitemAction = ({
    onClick,
    hasCompleted,
    isActiveItem,
    activeItems,
    isLoadingUserPreferences,
    itemLabel,
}) => {
    const [loading, setLoading] = useState();
    const [preferenceIsActiveItem, setPreferenceIsActiveItem] = useState(isActiveItem);
    const [initialActive, setInitialActive] = useState(true);

    const handleClick = () => {
        onClick();
        setLoading(true);
        setInitialActive(false);
    };

    useEffect(() => {
        if (hasCompleted) {
            setLoading(false);
        }
    }, [hasCompleted]);

    useEffect(() => {
        setPreferenceIsActiveItem(isActiveItem);
    }, [isActiveItem]);

    useEffect(() => {
        setInitialActive((prevState) => prevState !== activeItems);
    }, [activeItems]);

    return (
        <PreferenceListItem>
            <PreferenceListitemActionContainer>
                <H4 color={preferenceIsActiveItem ? undefined : 'muted'}>{itemLabel}</H4>
                {(loading || !initialActive) && (
                    <Button
                        skin={preferenceIsActiveItem ? 'muted' : 'success'}
                        size="sm"
                        btnType="outlined"
                        loading
                    />
                )}
                {!loading && preferenceIsActiveItem && initialActive && (
                    <TransitionItem appear inProp isFullWidth={false}>
                        <Button
                            icon="x"
                            skin="muted"
                            size="sm"
                            btnType="outlined"
                            onClick={handleClick}
                            loading={loading}
                            disabled={isLoadingUserPreferences}
                        >
                            <FormattedMessage id="account.preferences.brands.categories.button.label.remove" />
                        </Button>
                    </TransitionItem>
                )}
                {!loading && !preferenceIsActiveItem && initialActive && (
                    <TransitionItem appear inProp isFullWidth={false}>
                        <Button
                            icon="plus"
                            skin="success"
                            size="sm"
                            btnType="outlined"
                            onClick={handleClick}
                            loading={loading}
                            disabled={isLoadingUserPreferences}
                        >
                            <FormattedMessage id="account.preferences.brands.categories.button.label.add" />
                        </Button>
                    </TransitionItem>
                )}
            </PreferenceListitemActionContainer>
        </PreferenceListItem>
    );
};

PreferenceListitemAction.propTypes = {
    activeItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    isActiveItem: PropTypes.bool,
    itemLabel: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    hasCompleted: PropTypes.bool,
    isLoadingUserPreferences: PropTypes.bool,
};

PreferenceListitemAction.defaultProps = {
    isActiveItem: false,
    hasCompleted: false,
    isLoadingUserPreferences: false,
};

export default PreferenceListitemAction;
