import { GET_USER } from '../services/actionTypes';

const handleOnUserUpdateUtils = {
    username: async ({ userFromClient, client, setUserFromClient, updateUser, username }) => {
        let mutationData;

        client.cache.updateQuery(
            {
                query: GET_USER,
                variables: {
                    netlifyId: userFromClient.id,
                },
            },
            (currentData) => {
                if (!currentData) {
                    mutationData = {
                        ...userFromClient,
                        user_metadata: { ...userFromClient.user_metadata, full_name: username },
                    };
                } else {
                    mutationData = {
                        ...userFromClient,
                        ...currentData.findUserByNetlifyID,
                        user_metadata: { ...userFromClient.user_metadata, full_name: username },
                    };
                }
            },
        );

        setUserFromClient({ __typename: 'User', ...mutationData });

        await updateUser({
            variables: {
                ...mutationData,
            },
        });
    },

    propertyArray: async ({ userFromClient, client, setUserFromClient, updateUser, property }) => {
        const key = Object.keys(property).toString();
        const value = Object.values(property).toString();

        let mutationData;

        if (!userFromClient[key].includes(value)) {
            client.cache.updateQuery(
                {
                    query: GET_USER,
                    variables: {
                        netlifyId: userFromClient.id,
                    },
                },
                (currentData) => {
                    if (!currentData) {
                        mutationData = {
                            ...userFromClient,
                            [key]: [...userFromClient[key], value],
                        };
                    } else {
                        mutationData = {
                            ...userFromClient,
                            ...currentData.findUserByNetlifyID,
                            [key]: [...currentData.findUserByNetlifyID[key], value],
                        };
                    }

                    return {
                        findUserByNetlifyID: {
                            __typename: 'User',
                            ...mutationData,
                        },
                    };
                },
            );
        }

        if (userFromClient[key].includes(value)) {
            client.cache.updateQuery(
                {
                    query: GET_USER,
                    variables: {
                        netlifyId: userFromClient.id,
                    },
                },
                (currentData) => {
                    if (!currentData) {
                        mutationData = {
                            ...userFromClient,
                            [key]: currentData.findUserByNetlifyID[key].filter(
                                (item) => item !== value,
                            ),
                        };
                    } else {
                        mutationData = {
                            ...userFromClient,
                            ...currentData.findUserByNetlifyID,
                            [key]: currentData.findUserByNetlifyID[key].filter(
                                (item) => item !== value,
                            ),
                        };
                    }

                    return {
                        findUserByNetlifyID: {
                            __typename: 'User',
                            ...mutationData,
                        },
                    };
                },
            );
        }

        setUserFromClient({ __typename: 'User', ...mutationData });

        await updateUser({
            variables: {
                ...mutationData,
            },
        });
    },
};

export default handleOnUserUpdateUtils;
