import styled from 'styled-components';
import { rem } from 'polished';

const DashboardWelcome = styled.div`
    padding-top: 10vh;

    /** min-height - This is a workaround on the Dashboard view */
    /** Due to the Router wrapping the content in an extra div for accessibility reasons
     * the display flex layout of BrandSearchResultsOffset won't work
     * This is not an issue on the Account view given the taller view and content.
     * Ideally once the Dashboard view will have content to show, this workaround will
     * no longer be needed
     */
    min-height: ${({ theme }) =>
        `calc(65vh - ${rem(theme.header.height + theme.site.brandSearch.height)})`};
`;

export default DashboardWelcome;
