import { rem } from 'polished';
import styled from 'styled-components';

const PreferenceListitemActionContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ${rem(16)} ${rem(8)};
`;

export default PreferenceListitemActionContainer;
