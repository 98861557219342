import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { LargeH1 } from '@luigiclaudio/ga-baseline-ui/typography';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import MainContainer from '../../pageLayout/MainContainer';
import useAppUser from '../../../services/useAppUser';
import DashboardWelcome from './DashboardWelcome';
import DashboardTime from './DashboardTime';
import accountUtils from '../../../utils/accountUtils';
import SiteFooter from '../../pageLayout/SiteFooter';

const Dashboard = () => {
    const intl = useIntl();
    const theme = useContext(ThemeContext);
    const { username } = useAppUser();
    const [now, setNow] = useState();

    useEffect(() => {
        accountUtils.greetingTime(intl, (v) => setNow(v));
    }, []);

    return (
        <DefaultTemplate
            bgStart={theme.site.stage.backgroundColor}
            bgEnd={theme.site.stage.backgroundColor}
            content={
                <MainContainer>
                    <SiteContentWrapper>
                        <Container isFullHeight>
                            <Row justifyContent="center" isFullHeight>
                                <Col xxs={30} lg={26}>
                                    <DashboardWelcome>
                                        <TransitionItem inProp={!!now} appear>
                                            <DashboardTime>{now}</DashboardTime>
                                        </TransitionItem>
                                        <TransitionItem inProp={!!username} appear>
                                            <LargeH1>
                                                <FormattedMessage
                                                    id="account.greeting"
                                                    values={{
                                                        username,
                                                        greeting: intl.formatMessage({
                                                            id: accountUtils
                                                                .greetingTime(intl)
                                                                .greetingLabel(),
                                                        }),
                                                    }}
                                                />
                                            </LargeH1>
                                        </TransitionItem>
                                    </DashboardWelcome>
                                </Col>
                            </Row>
                        </Container>
                    </SiteContentWrapper>
                </MainContainer>
            }
            footer={<SiteFooter />}
        />
    );
};

export default Dashboard;
