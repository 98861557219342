import styled from 'styled-components';

const PreferenceListItem = styled.li`
    border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLight};

    &:last-child {
        border-bottom: 0;
    }
`;

export default PreferenceListItem;
